import { useRef } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import './stylesheets/inputForm.scss';
import { isValidEmail } from '../helpers';

function InputForm({ prompt, validator, onClose }) {
  const inputRef = useRef();
  const [error, setError] = useState();

  return (
    <Modal
      isOpen
      onRequestClose={() => onClose()}
      className="input-form custom-dialog"
      overlayClassName="custom-dialog-overlay"
      shouldCloseOnOverlayClick
    >
      <h2>{prompt}</h2>
      <input
        type="text"
        autoFocus
        ref={inputRef}
        onInput={(e) => {
          if (error) setError('');
        }}
      />
      {error && <div className="error">{error}</div>}
      <div className="buttons">
        <button type="button" className="btn-cancel" onClick={() => onClose()}>Cancel</button>
        <button
          type="button"
          className="btn-send"
          onClick={() => {
            if (validator === 'email') {
              const email = (inputRef?.current.value || '').trim();
              if (isValidEmail(email)) {
                onClose(email);
              } else {
                setError('Invalid email');
              }
            }
          }}
        >
          Send
        </button>
      </div>
    </Modal>
  );
}

export default InputForm;