import Vapi from '@vapi-ai/web';
import './stylesheets/assistant.scss';
import { useEffect, useRef, useState } from 'react';
import { getConfig } from '../config';
import InputForm from './inputForm';
import { isValidEmail } from '../helpers';

function Assistant() {
    const [vapi, setVapi] = useState();
    const [status, setStatus] = useState();
    const [volume, setVolume] = useState();
    const [showInputForm, setShowInputForm] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const statusRef = useRef();
    const inputRef = useRef();

    const setStatusEx = (newStatus) => {
        setStatus(newStatus);
        statusRef.current = newStatus;
    };

    useEffect(() => {
        const v = new Vapi(getConfig().vapiPublicKey);
        console.log('app version: 2');
        v.on('call-start', () => {
            console.log('Call has started');
            setStatusEx('running');
        });
        v.on('call-end', () => {
            console.log('Call has stopped');
            setStatusEx(undefined);
        });
		v.on('speech-start', () => {
            console.log('Speech has started');
            setStatusEx('running');
        });

        v.on('speech-end', () => { console.log('Speech has ended'); });
        v.on('error', (err) => { console.error(err) });
        v.on('volume-level', volume => setVolume(volume));

        v.on('message', (message) => {
            console.log(message);
            if (message.type === 'function-call' && message.functionCall?.name === 'show_email_form') {
                setShowInputForm('email');
            } else if (message.type === 'function-call' && (message.functionCall?.name === 'email_address_before_confirm' || message.functionCall?.name === 'email_address_confirmed')) {
                const { email } = message.functionCall.parameters;
                setEmail(email);
                if (!isValidEmail(email)) {
                    console.log('Email is not valid', email);
                    v.send({
                        type: 'add-message',
                        message: {
                            role: 'system',
                            content: 'The email provided by the user is not valid, ask the user to provide a correct email.',
                        },
                    });
                }
            } else if (message.type === 'function-call' && (message.functionCall?.name === 'phone_number_before_confirm' || message.functionCall?.name === 'phone_number_confirmed')) {
                setPhone(message.functionCall.parameters?.phone);
            }
        });


        setVapi(v);

        return () => {
            if (statusRef.current === 'running') {
                v.stop();
            }
        }
    }, []);

    return (
        <>
            <div className={`assistant ${status || ''}`}>
                <h2>Instant Legal Consultation</h2>
                <p>Ask me anything about ABC Law Firm</p>
                <button
                    type="button"
                    className="btn-mic"
                    disabled={status === 'starting'}
                    onClick={() => {
                        if (status !== 'starting' && status !== 'running') {
                            setStatusEx('starting');
                            vapi.start(getConfig().vapiAssistantId);
                        } else {
                            setStatusEx('stopping');
                            setEmail(undefined);
                            setPhone(undefined);
                            vapi.stop();
                        }
                    }}
                />

                <div className="volume">
                    <div className="bar" style={{ width: `${(volume || 0) * 100}%` }} />
                </div>
                {false && (
                    <div className="block">
                        <input type="text" ref={inputRef} />
                        <button
                            type="button"
                            onClick={() => {
                                const input = inputRef.current;
                                vapi.send({
                                    type: 'add-message',
                                    message: {
                                        role: 'user',
                                        content: input.value,
                                    },
                                });

                                input.value = '';
                            }}
                        >
                            Send
                        </button>
                    </div>
                )}
                {email && (
                    <div className="email-block">
                        Email: {email}
                    </div>
                )}
                {phone && (
                    <div className="email-block">
                        Phone: {phone}
                    </div>
                )}
            </div>
            {showInputForm && (
                <InputForm
                    prompt={showInputForm === 'email' ? 'Input your email' : 'Input your Phone'}
                    validator={showInputForm}
                    onClose={(result) => {
                        setShowInputForm(undefined);
                        if (result) {
                            if (showInputForm === 'email') {
                                vapi.send({
                                    type: 'add-message',
                                    message: {
                                        role: 'user',
                                        content: `My email is: ${result}`,
                                    },
                                });
                            }
                        }
                    }}
                />
            )}
        </>
    );
}

export default Assistant;