import { NavLink, BrowserRouter } from 'react-router-dom';
import './app.scss';
import Home from './components/home';
import LogoIcon from './images/logo.svg';

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <div className="header">
          <NavLink to="/">
            <img src={LogoIcon} alt="Logo" className="logo" />
          </NavLink>
          <h1>ABC Law Firm</h1>
          <div className="nav-links">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/">Products</NavLink>
              <NavLink to="/">Company</NavLink>
              <NavLink to="/">Blog</NavLink>
          </div>
        </div>
        <div className="content-container">
          <Home />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
