import Assistant from "./assistant";
import './stylesheets/home.scss';

function Home() {
    return (
        <div className="home">
            <Assistant />
        </div>
    );

}

export default Home;
